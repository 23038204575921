import Container from '../../../containers';
import AdminAdministrationMankaContainer from '../../../containers/admin/administration/manka';

const AdminAdministrationMankaPage = () => {
  const pageTitle = 'Manka';
  const columns = [];
  const data = [];
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onSearch = () => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onFilter = () => {};

  return (
    <Container
      iconcolor="#B3CA1F"
      background="#fff"
      elevate
      contentstyle={{ paddingLeft: '170px' }}
      desktopHeader
      sidebar
      sidebarExtra
    >
      <AdminAdministrationMankaContainer
        pageTitle={pageTitle}
        columns={columns}
        data={data}
        onSearch={onSearch}
        onFilter={onFilter}
      />
    </Container>
  );
};

export default AdminAdministrationMankaPage;
