import ExclamationCircleOutlined from '@ant-design/icons/ExclamationCircleOutlined';
import { Paginated } from '@feathersjs/feathers';
import { isValidBankAccount } from '@tymbe/bank-account';
import { PaymentStatusType } from '@tymbe/schema/enums';
import { PaymentRequestData } from '@tymbe/schema/payment-request.interface';
import { PaymentStatusData } from '@tymbe/schema/payment-status.interface';
import { PaymentTransactionData } from '@tymbe/schema/payment-transaction.interface';
import AlertModal from '@tymbe/ty-components/modals/AlertModal';
import getResponseArray from '@tymbe/utils/getResponseArray';
import { Checkbox } from 'antd';
import moment from 'moment';
import { useEffect, useState, useCallback, Dispatch, SetStateAction, ComponentPropsWithRef } from 'react';
import CsvDownloader from 'react-csv-downloader';
import { UseMutateAsyncFunction, useMutation, useQuery, useQueryClient } from 'react-query';

import PayoutAmountForm from './PayoutAmountForm';
import PayoutStatusButton from './payoutStatus/PayoutStatusButton';
import PayoutStatusModal from './payoutStatus/PayoutStatusModal';
import columns from './utils/columns';
import PaymentRequestRow from './utils/PaymentRequestRow';
import feathersClient from '../../../../apiClient';
import { ErrorAlert } from '../../../../components/alerts';
import { SecondaryButton } from '../../../../components/buttons';
import { TableRangePicker } from '../../../../components/inputs';
import SearchBox from '../../../../components/search/SearchBox';
import {
  Table,
  TableHead,
  TablePagination,
  TableRow,
  TableSortHeader,
  useTablePagination,
} from '../../../../components/Table';
import TableSelectHeader from '../../../../components/Table/TableSelectHeader';
import { PageTitle } from '../../../../components/texts';
import Wrapper from '../../../../components/wrapper';
import useURLParamsHandler from '../../../../hooks/UrlParamsHandler/useURLParamsHandler';
import { transformAdminPaymentConfirmation, transformPaymentTransactionToPaymentConfirmation } from '../../../../utils';
import { mapPaymentsForCEBExport, mapPaymentsForExport } from '../../../../utils/exports/csv';
import exportDataToXLSX from '../../../../utils/exports/xlsx/xlsx';

interface AdminAdministrationPaymentConfirmationContainerProps {
  fetchCSVData: UseMutateAsyncFunction<Paginated<PaymentRequestData>>,
  onSort: (columnKey: string, sortOrder: number) => void,
  onDateChange: Dispatch<SetStateAction<Date[] | null>>;
  onChangePage: (pageStart: number, pageEnd: number) => void,
  onParamsChange: Dispatch<SetStateAction<boolean>>
  onSearch: (value: string) => void,
  onSubmitPayoutAmount: (inputAmount: string) => void,
  sortField: string;
  paymentRequestData: Paginated<PaymentRequestData>,
  isLoading: boolean;
  pageTitle: string;
  payoutAmount?: number;
}

const AdminAdministrationPaymentConfirmationContainer = ({
  paymentRequestData,
  isLoading,
  pageTitle,
  onParamsChange,
  onDateChange,
  onChangePage,
  onSearch,
  onSort,
  fetchCSVData,
  onSubmitPayoutAmount,
  sortField,
  payoutAmount,
}: AdminAdministrationPaymentConfirmationContainerProps) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const [showPayoutStatusModal, setShowPayoutStatusModal] = useState(false);
  const [showConfirmPanel, setShowConfirmPanel] = useState(false);
  const [selectedPaymentAmount, setSelectedPaymentAmount] = useState(0);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const queryClient = useQueryClient();
  const [{ allSearchParams }, setUrlParamsHandler] = useURLParamsHandler();
  const { setPageParams, onChangePage: onChangePageInner } = useTablePagination();
  const [modalProps, setModalProps] = useState<ComponentPropsWithRef<typeof AlertModal>>();

  const toggleSelectAll = () => {
    const allRowKeys = paymentRequestData?.data?.map((row) => row.id);
    if (selectedRowKeys.length === allRowKeys.length) {
      setSelectedRowKeys([]);
      setSelectAllChecked(false);
    } else {
      setSelectedRowKeys(allRowKeys);
      setSelectAllChecked(true);
    }
  };

  const toggleRow = (rowKey: number) => {
    const index = selectedRowKeys.indexOf(rowKey);
    if (index > -1) {
      const newSelectedRowKeys = [...selectedRowKeys];
      newSelectedRowKeys.splice(index, 1);
      setSelectedRowKeys(newSelectedRowKeys);
      setSelectAllChecked(false);
    } else {
      setSelectedRowKeys([...selectedRowKeys, rowKey]);
      const allRowKeys = paymentRequestData.data.map((row) => row.id);
      if ([...selectedRowKeys, rowKey].length === allRowKeys.length) {
        setSelectAllChecked(true);
      }
    }
  };

  const countSelectedPaymentAmount = useCallback(() => {
    const totalAmount = paymentRequestData?.data?.filter(
      (row) => selectedRowKeys.includes(row.id),
    ).map((row) => row.amount).reduce((a, b) => Number(a) + Number(b), 0);
    return totalAmount;
  }, [paymentRequestData, selectedRowKeys]);

  useEffect(() => {
    setShowConfirmPanel(selectedRowKeys.length > 0);
    setSelectedPaymentAmount(countSelectedPaymentAmount);
  }, [countSelectedPaymentAmount, selectedRowKeys, setShowConfirmPanel]);

  const [showSearch, setShowSearch] = useState(false);
  const [onlyUnresolved, setOnlyUnresolved] = useState(
    allSearchParams.onlyUnresolved === undefined ? true : allSearchParams.onlyUnresolved,
  );

  const { data: payoutStatusData } = useQuery(['payoutStatus'], async () =>
    getResponseArray(await feathersClient.service('payment-status').find(
      {
        query: {
          status_type: PaymentStatusType.PAYOUT_STATUS,
        },
      },
    )));

  const { mutateAsync: patchTrafficLight } = useMutation('patchTrafficLight', async (body: Partial<PaymentStatusData>) =>
    feathersClient.service('payment-status').patch(
      PaymentStatusType.PAYOUT_STATUS,
      body,
    ));

  const { mutateAsync: createTrafficLight } = useMutation('createTrafficLight', async (body: Partial<PaymentStatusData>) =>
    feathersClient.service('payment-status').create(
      {
        status_type: PaymentStatusType.PAYOUT_STATUS,
        value: body.value,
      },
    ));

  const { mutateAsync: createPaymentTransaction } = useMutation('createPaymentTransaction', async (body) =>
    feathersClient.service('payment-transaction').create(
      body,
      { query: { $eager: '[paymentRequest.[creditTransaction.[person.personData], paymentAuthorized.[person.personData, company]]]' } },
    ));

  const { mutateAsync: patchPaymentTransaction } = useMutation('patchPaymentTransaction', async (body) =>
    feathersClient.service('payment-transaction').patch(
      body.id,
      body,
      { query: { $eager: '[paymentRequest.[creditTransaction.[person.personData], paymentAuthorized.[person.personData, company]]]' } },
    ));

  const { mutateAsync: deletePaymentTransaction } = useMutation('deletePaymentTransaction', async (body) =>
    feathersClient.service('payment-transaction').remove(body.id));

  const showConfirmModal = () => {
    const onOkClick = async () => {
      try {
        const patchRequests: Partial<PaymentTransactionData>[] = [];
        const createRequests: Partial<PaymentTransactionData>[] = [];
        paymentRequestData?.data?.filter((row) => selectedRowKeys.includes(row.id)).forEach((row) => {
          const person = row.credit_payout ? row.creditTransaction?.person : row.paymentAuthorized?.person;
          if (!person) {
            ErrorAlert('Data obsahují vadný záznam, který k sobě nemá přiřazenou osobu.');
            throw new Error();
          }
          if (row.paymentTransaction) {
            patchRequests.push({
              id: row.paymentTransaction.id,
              exported_at: new Date().toISOString(),
            });
          } else if (isValidBankAccount(person.personData?.bank_account)) {
            createRequests.push({
              person_id: person.id,
              payment_request_id: row.id,
              bank_account: person.personData?.bank_account as string,
              amount: Number(row.amount),
              exported_at: new Date().toISOString(),
            });
          }
        });

        const result = [];
        if (patchRequests.length > 0) {
          // Feathers can't handle multi patch with different bodies
          await Promise.all(patchRequests.map(async (row) => {
            result.push(await patchPaymentTransaction(row));
          }));
        }
        if (createRequests.length > 0) {
          result.push(...await createPaymentTransaction(createRequests));
        }

        const rows = transformPaymentTransactionToPaymentConfirmation(result);
        const mappedCEBData = mapPaymentsForCEBExport(rows);

        const fileName = `${moment().format('YYYYMMDD')}_CEB_Payment`;
        exportDataToXLSX(fileName, mappedCEBData, true);

        setSelectedRowKeys([]);
        setSelectAllChecked(false);
        queryClient.invalidateQueries(['admin/loadPaymentRequest']);
      } catch {
        ErrorAlert('Při vytváření CEB exportu došlo k chybě');
      }
    };
    setModalProps({
      title: <><ExclamationCircleOutlined /> Potvrdit odeslání výplaty</>,
      onConfirm: () => {
        onOkClick();
        setModalProps(undefined);
      },
      onCancel: () => {
        setModalProps(undefined);
      },
      message: (
        <>
          Potvrzením stáhnete xlsx soubor s CEB formatováním, který lze importovat do
          jiných programů a vytvoříte záznam o exportu. <br />
          <br />
          Počet vybraných položek: {selectedRowKeys.length}
        </>
      ),
      showModal: true,
    });
  };

  const showDeleteModal = () => {
    const onOkClick = async () => {
      try {
        const patchRequests: Partial<PaymentTransactionData>[] = [];
        paymentRequestData?.data?.filter((row) => selectedRowKeys.includes(row.id)).forEach((row) => {
          if (row.paymentTransaction) {
            patchRequests.push({
              id: row.paymentTransaction.id,
              payment_request_id: null,
            });
          }
        });

        if (patchRequests.length > 0) {
          // Feathers can't handle multi patch with different bodies
          await Promise.all(patchRequests.map(async (row) => {
            await patchPaymentTransaction(row);
            await deletePaymentTransaction(row);
          }));
        }
        setSelectedRowKeys([]);
        setSelectAllChecked(false);
        queryClient.invalidateQueries(['admin/loadPaymentRequest']);
      } catch {
        ErrorAlert('Při mazání transakcí došlo k chybě');
      }
    };
    setModalProps({
      title: <><ExclamationCircleOutlined />Potvrdit smazání transakcí</>,
      onConfirm: () => {
        onOkClick();
        setModalProps(undefined);
      },
      onCancel: () => {
        setModalProps(undefined);
      },
      message: (
        <div>
          <br />
          Počet vybraných položek: {selectedRowKeys.length}
        </div>
      ),
      showModal: true,
    });
  };

  const onSubmitPayoutStatus = async ({ values }) => {
    const payoutStatusForm = {
      value: values.payoutStatus,
    };
    if (!payoutStatusData?.length) {
      try {
        await createTrafficLight(payoutStatusForm);
        setShowPayoutStatusModal(false);
      } catch {
        ErrorAlert('Při editaci semaforu došlo k chybě');
      }
    } else {
      try {
        await patchTrafficLight(payoutStatusForm);
        setShowPayoutStatusModal(false);
      } catch {
        ErrorAlert('Při editaci semaforu došlo k chybě');
      }
    }
    queryClient.invalidateQueries(['payoutStatus']);
  };

  const onCSVClick = async () => {
    const newCSV = await fetchCSVData();
    return mapPaymentsForExport(transformAdminPaymentConfirmation(newCSV.data));
  };

  const onUnresolvedCheckboxChange = (event) => {
    const { checked } = event.target;
    setOnlyUnresolved(checked);
    onParamsChange(checked);
    setUrlParamsHandler({ onlyUnresolved: checked });
  };

  return (
    <Wrapper padding="0px" margin="0px 22px 18px 31px">
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <PageTitle>{pageTitle}</PageTitle>
        <div className="flex">
          <PayoutStatusButton
            payoutStatusData={payoutStatusData}
            onClick={() => setShowPayoutStatusModal(true)}
          />
          <PayoutStatusModal
            initialValues={{ payoutStatus: payoutStatusData ? payoutStatusData[0]?.value : undefined }}
            onClose={() => setShowPayoutStatusModal(false)}
            showModal={showPayoutStatusModal}
            onSubmit={onSubmitPayoutStatus}
          />
          <SecondaryButton
            style={{ marginRight: '18px' }}
            onClick={showDeleteModal}
            size="large"
            buttontext="Smazat transakci"
          />
          <SecondaryButton
            style={{ marginRight: '18px' }}
            onClick={showConfirmModal}
            size="large"
            buttontext="CEB ČSOB"
          />
          <CsvDownloader filename={`${moment().format('YYYYMMDD')}_Payment_Confirmation_Table.csv`} datas={onCSVClick}>
            <SecondaryButton
              style={{ marginRight: '18px' }}
              size="large"
              buttontext="Export CSV"
            />
          </CsvDownloader>
        </div>
      </div>
      <>
        <div className="bg-bg min-w-fit">
          <div className="px-2.5 text-center space-y-5">
            <div className="flex justify-between">
              <div className="flex align-center">
                <TableRangePicker disabled={payoutAmount} onDateChange={onDateChange} />
              </div>
              <div className="flex justify-end">
                <SearchBox
                  name=""
                  showSearch={showSearch && !payoutAmount}
                  setShowSearch={setShowSearch}
                  onParamsSearch={onSearch}
                  loading={isLoading}
                />
              </div>
            </div>

            <div
              className="w-full flex pb-4 items-center justify-between"
            >
              <div className="ml-2">
                <PayoutAmountForm onSubmitPayoutAmount={onSubmitPayoutAmount} />
              </div>
              <Checkbox
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
                checked={onlyUnresolved}
                onChange={onUnresolvedCheckboxChange}
                disabled={!!payoutAmount}
              >
                <small>Pouze nezpracované</small>
              </Checkbox>
            </div>
          </div>
          <Table>
            <TableHead>
              <TableRow>
                <TableSelectHeader selectAll={toggleSelectAll} checked={selectAllChecked} />
                {columns.map((col) => (
                  <TableSortHeader
                    key={col.value}
                    field={col.sortable ? col.value : undefined}
                    activeField={sortField}
                    onSort={onSort}
                  >
                    {col.label}
                  </TableSortHeader>
                ))}
              </TableRow>
            </TableHead>
            <tbody>
              {paymentRequestData?.data?.map(
                (paymentRequest: PaymentRequestData) => (
                  <PaymentRequestRow
                    key={paymentRequest.id}
                    paymentRequest={paymentRequest}
                    onChange={toggleRow}
                    checked={selectedRowKeys.includes(paymentRequest.id)}
                  />
                ),
              )}
            </tbody>
          </Table>
        </div>
        <TablePagination
          {...setPageParams()}
          rowsCount={paymentRequestData?.total}
          onChangePage={(pageStart, pageEnd, pageSize) => {
            onChangePageInner(pageStart, pageEnd, pageSize);
            onChangePage(pageStart, pageEnd);
          }}
        />
      </>
      {modalProps?.showModal && <AlertModal {...modalProps} />}
      {showConfirmPanel ? (
        <div>Součet vybraných částek k vyplacení je {selectedPaymentAmount?.toFixed(2)} Kč</div>
      ) : null}
    </Wrapper>
  );
};

export default AdminAdministrationPaymentConfirmationContainer;
