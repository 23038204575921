import { useQuery } from 'react-query';
import { getBlobFromBucket } from './blob.utils';

export const useBlobFromBucket = (src?: string) => {
  const {
    data: blob,
    isLoading,
    isError,
  } = useQuery(
    ['getBlob', src],
    () => {
      if (!src) return Promise.reject(new Error('src not provided'));
      return getBlobFromBucket(src);
    },
    { retry: src ? 3 : 0, enabled: !!src }
  );

  if (!src) return { isError: true };

  return {
    blob,
    isLoading,
    isError,
  };
};
