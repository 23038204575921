import classNames from 'classnames';
import Modal from 'react-modal';

interface ModalShellProps {
  showModal: boolean;
  className?: string;
  children?: React.ReactNode;
  onClose?: () => void;
}

export const ModalShell = ({
  showModal,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClose = () => {},
  children = '',
  className = '',
}: ModalShellProps) => (
  <Modal
    isOpen={showModal}
    onRequestClose={onClose}
    ariaHideApp={false}
    closeTimeoutMS={300}
    shouldCloseOnOverlayClick={true}
    contentLabel="My dialog"
    className={classNames(
      className,
      'left-[50%]',
      'top-[50%]',
      '-translate-y-1/2',
      '-translate-x-1/2',
      `fixed bg-base-back-inverse translate-1/2 rounded-xl drop-shadow-lg max-w-[450px]`
    )}
    overlayClassName={{
      base: classNames(
        'bg-base-back-overlay-1 fixed inset-0 z-modal',
        'transition-all duration-300 ease-in-out',
        'opacity-0'
      ),
      afterOpen: 'opacity-100',
      beforeClose: '!opacity-0',
    }}
  >
    {children}
  </Modal>
);
