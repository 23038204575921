import classNames from 'classnames';
import { ComponentProps } from 'react';

import Emoji from './Emoji';
import CrossIcon from './icons/CrossIcon';

type ChipOwnProps = {
  emoji?: string | null,
  shiftName: string,
  isSelected?: boolean,
  isRemovable?: boolean,
  onRemove?: () => void,
  className?: string,
};
type ChipProps = ChipOwnProps & Omit<ComponentProps<'div'>, keyof ChipOwnProps>;

const Chip = ({
  emoji,
  shiftName,
  isSelected = false,
  isRemovable = false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onRemove = () => {},
  className = '',
  ...rest
}: ChipProps) => (
  <div
    className={
      classNames(
        'inline-flex items-center gap-[6px] p-1 pr-2 text-secondary-900 !rounded-lg border-[1px]',
        { ' border-bg hover:border-secondary-50 hover:bg-secondary-50 bg-bg': !isSelected },
        { 'bg-primary-100 border-primary-300 hover:bg-primary-150': isSelected },
        className,
      )
    }
    {...rest}
  >
    <Emoji className="w-6 h-6 text-[14px] leading-[14px] bg-secondary-50" emoji={emoji} />
    <span>{shiftName}</span>
    {isRemovable
    && (
      <button type="button" className="p-0" aria-label="remove button" onClick={() => onRemove()}>
        <CrossIcon className="w-4" />
      </button>
    )}
  </div>
);

export default Chip;
