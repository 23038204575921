import { ReleaseAdvancesData } from '@tymbe/schema/release-advances.interface';
import { Form } from 'informed';

import { SubmitButton } from '../buttons';
import ModalShell from '../modals/ModalShell';

type ReleaseAdvancesModalProps = {
  advancesAreBeingReleased: boolean;
  onClose: () => void;
  onSubmit: () => void;
  releaseAdvancesResult?: ReleaseAdvancesData & { error?: string };
  showModal: boolean;
};

const ReleaseAdvancesModal = ({
  advancesAreBeingReleased,
  onClose,
  onSubmit,
  releaseAdvancesResult,
  showModal,
}: ReleaseAdvancesModalProps) => (
  <ModalShell
    /* eslint-disable-next-line @typescript-eslint/no-empty-function */
    onClose={advancesAreBeingReleased ? () => {} : onClose}
    showModal={showModal}
  >
    <div className="flex flex-row justify-between items-center">
      <h2>
        Uvolnění záloh
      </h2>
    </div>

    {releaseAdvancesResult && (
      <>
        <div className="flex flex-row justify-between items-center mb-5">
          {
            releaseAdvancesResult.error
              ?? `Zálohy za měsíc ${releaseAdvancesResult.month}/${releaseAdvancesResult.year} pro ${releaseAdvancesResult.tymbersCount} Tymberů vyplaceny v celkové výši ${releaseAdvancesResult.sumOfAdvances} Kč.`
          }
        </div>

        <div className="flex gap-x-1 justify-end">
          <button
            className="ty-button-secondary"
            onClick={onClose}
            type="button"
          >
            Zavřít
          </button>
        </div>
      </>
    )}

    {!releaseAdvancesResult && (
      advancesAreBeingReleased
        ? (
          <div className="flex flex-row justify-between items-center mb-5">
            Uvolňování záloh právě probíhá.
            Neopouštějte prosím tuto stránku.
          </div>
        ) : (
          <>
            <div className="flex flex-row justify-between items-center mb-5">
              Opravdu chcete nyní spustit uvolnění záloh za předchozí měsíc?
              <br />
              Tato operace může trvat i několik minut,
              během kterých není doporučeno opouštět tuto stránku a provádět další úkony v aplikaci.
            </div>

            <Form onSubmit={onSubmit}>
              <div className="flex gap-x-1 justify-end">
                <button
                  className="ty-button-secondary"
                  onClick={onClose}
                  type="button"
                >
                  Zrušit
                </button>
                <SubmitButton
                  buttontext="Uvolnit zálohy"
                  className="ty-button-primary"
                />
              </div>
            </Form>
          </>
        )
    )}
  </ModalShell>
);

export default ReleaseAdvancesModal;
