import { createContext } from 'react';

export interface NotificationOptions {
  buttonText?: string;
  hideButton?: boolean;
  onClick?: () => void;
}

export interface NotificationProps {
  id: number,
  message: string;
  options?: NotificationOptions;
}

interface NotificationContextProps {
  notifications: NotificationProps[],
  createNotification: (message: string, options?: NotificationOptions) => void;
  removeNotification: (id: number) => void;
}

export const NotificationContext = createContext<NotificationContextProps>({
  notifications: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  removeNotification: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  createNotification: () => {},
});

export const NotificationContextProvider = NotificationContext.Provider;
