import { Paginated } from '@feathersjs/feathers';
import { CompanyData } from '@tymbe/schema/company.interface';
import { TYMBE_ID } from '@tymbe/schema/constants';
import { ContractDocumentTypes, DocumentType } from '@tymbe/schema/enums';
import { GroupBase, OptionsOrGroups } from 'react-select';

import { Option } from './TySelectRequirements.types';
import feathersClient from '../../../apiClient';
import { DocumentTypeData, DocumentTypeEnum, isPerkData, PerkData, RolesData } from '../../../types/TymbeApi';
import { getPerkNameById, getRequirementsGroupName } from '../../../utils';
import { PerkId, Roles } from '../../../utils/enums';

export const getCompanyContractorIds = async (companyId: number | string): Promise<Array<number>> => {
  const company: CompanyData = await feathersClient.service('company').get(
    companyId,
    { query: { $eager: '[contractor]' } },
  );
  return company.contractor?.map((contractor) => contractor.id) ?? [];
};

export const findDocumentTypes = async (
  search: string,
  companyId: number | undefined,
  $skip: number,
): Promise<Paginated<DocumentTypeData>> => {
  const companyContractorIds = companyId ? await getCompanyContractorIds(companyId) : [];

  return feathersClient
    .service('document-type')
    .find({
      query: {
        $or: [
          { company_id: { $in: companyContractorIds } },
          {
            name: { $ilike: `%${search}%` },
            $or: companyId && [{ company_id: companyId }, { company_id: { $null: true } }],
          },
          {
            display_name: { $ilike: `%${search}%` },
            $or: companyId && [{ company_id: companyId }, { company_id: { $null: true } }],
          },
        ],
        type: {
          $nin: [
            DocumentTypeEnum.TYMBE_CONTRACT,
            DocumentTypeEnum.CONTRACT_DPP_TEMPLATE,
            DocumentTypeEnum.CONTRACT_DPC_TEMPLATE,
            DocumentTypeEnum.CONTRACT_HPP_TEMPLATE,
          ],
        },
        category: {
          $null: true,
          // $nin would be better, but it doesn't retrieve category being null
          // $nin: [ DocumentCategory.WAGE_RELATED ],
        },
        $skip,
        $sort: { type: 1 },
      },
    });
};

export const generatePerkOptionGroups = (perks: PerkData[]): GroupBase<PerkData>[] => {
  const uniquePerkGroups = new Set(perks.map((perk) => perk.group));
  return Array.from(uniquePerkGroups).map((perkGroup) => ({
    label: getRequirementsGroupName(perkGroup),
    options: perks.filter((perk) => perk.group === perkGroup),
  }));
};

const perkService = feathersClient.service('perk');

export const getRequirementLabel = (option: Option, companyId?: number | string): string => {
  if (isPerkData(option)) {
    const optionLabel = option.is_visible ? `${getPerkNameById(option.id)} ` : `${getPerkNameById(option.id)} 🫣`;
    return optionLabel;
  }

  const showContractorLabel = option.company_id && option.company_id !== Number(companyId);

  return `[${option.id}] ${showContractorLabel ? '[Kontraktor]' : ''} ${option.display_name || option.name}`;
};

export const getRequirementValue = (option: Option): string => {
  if (isPerkData(option)) return option.group || option.id;

  return String(option.id);
};

export const loadRequirementOptions = (
  companyId?: number | string,
  userRole?: RolesData[],
) => async (
  search: string,
  prevOptions: OptionsOrGroups<Option, GroupBase<Option>>,
) => {
  const perks = ((await perkService.find({ query: { $limit: -1 } })) as PerkData[]).filter(
    ({ id }) => getPerkNameById(id).toLocaleLowerCase().includes(search.toLocaleLowerCase()),
  );
  let contractorIds: number[] | undefined;

  if (companyId) {
    contractorIds = await getCompanyContractorIds(companyId);
  }

  let perksToFilter = perks;
  // billa perks can only be seen by billa
  if (companyId !== 153) {
    perksToFilter = perks.filter((perk) => ![PerkId.BILLA_BAKERY, PerkId.BILLA_SERVICE_DESK].includes(perk.id));
  }

  const filteredPerks = perksToFilter.filter((perk) => perk.is_visible === true);
  let perkOptions = !prevOptions.length ? generatePerkOptionGroups(filteredPerks) : [];

  if (userRole && userRole.some((role) => [
    Roles.SUPER_ADMIN,
    Roles.ADMIN,
    Roles.TYMBE_ADMIN,
    Roles.TYMBE_COORDINATOR,
  ].includes(role.slug))) {
    perkOptions = !prevOptions.length ? generatePerkOptionGroups(perksToFilter) : [];
  }

  const prevDocumentOptionsLength = prevOptions
    .filter(
      (opt) =>
        'label' in opt
          && 'options' in opt
          && ['documents', 'contracts'].some((type) => opt.label === getRequirementsGroupName(type)),
    ).map((opt) => (opt as GroupBase<Option>).options.length).reduce((sum: number, opt) => sum + opt, 0);

  const documentTypes = await findDocumentTypes(
    search,
    Number.isNaN(Number(companyId)) ? undefined : Number(companyId),
    prevDocumentOptionsLength,
  );

  function filterHppDocuments(doc: DocumentTypeData) {
    return doc.type !== DocumentTypeEnum.CONTRACT_HPP || contractorIds?.includes(TYMBE_ID);
  }

  const contractOptions = {
    label: getRequirementsGroupName('contracts'),
    options: documentTypes.data.filter(
      (doc: DocumentTypeData) =>
        ContractDocumentTypes.includes(doc.type as unknown as DocumentType) // FIXME TD-2129
            && filterHppDocuments(doc),
    ),
  };

  const documentTypesOptions = {
    label: getRequirementsGroupName('documents'),
    options: documentTypes.data.filter(
      (doc: DocumentTypeData) =>
        !ContractDocumentTypes.includes(doc.type as unknown as DocumentType), // FIXME TD-2129
    ),
  };

  return {
    options: [...perkOptions, contractOptions, documentTypesOptions],
    hasMore: documentTypes.total > prevDocumentOptionsLength + documentTypes.data.length,
  };
};

export const reduceRequirementOptions = (
  prevOptions: OptionsOrGroups<Option, GroupBase<Option>>,
  newOptions: OptionsOrGroups<Option, GroupBase<Option>>,
) => {
  const groups: Record<string, GroupBase<Option>> = {};
  const options: Option[] = [];

  function extractOptions(option: Option | GroupBase<Option>) {
    if ('options' in option) {
      const { label, options: opts } = option;
      if (label) {
        groups[label] = !groups[label]
          ? option
          : { label, options: [...groups[label].options, ...opts] };
      } else options.concat(option.options);
    } else {
      options.push(option);
    }
  }

  prevOptions.forEach(extractOptions);
  newOptions.forEach(extractOptions);

  return [...Object.values(groups), ...options];
};
