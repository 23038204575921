import getResponseArray from '@tymbe/utils/getResponseArray';
import { Form, FormState } from 'informed';
import { useCallback, useState } from 'react';
import Modal from 'react-modal';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';

import CrossIcon from './icons/CrossIcon';
import EditIcon from './icons/EditIcon';
import TrashIcon from './icons/TrashIcon';
import Options from './Options';
import AvailableSpace from './ShiftDetail/AvailableSpace';
import Participants, { ParticipantsFormValues } from './ShiftDetail/Participants';
import ShiftDetail from './ShiftDetail/ShiftDetail';
import ShiftDetailForm, { ShiftDetailFormValues } from './ShiftDetail/ShiftDetailForm';
import feathersClient from '../../../apiClient';
import { useUser } from '../../../apiClient/ApiContext';
import { ErrorAlert, SuccessAlert } from '../../../components/alerts';
import Spinner from '../../../components/Spinner';
import {
  ApplicationData, ApplicationState,
  isDocumentTypeData,
  isPerkData,
  ManShiftData,
  ShiftData,
} from '../../../types/TymbeApi';
import styles from '../dev/shift-detail/Modal.module.css';

type ShiftDetailExtendedProps = {
  shiftId: number;
  onShiftRemoved?: (shift: ShiftData) => void;
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
const ShiftDetailExtended = ({ shiftId: id, onShiftRemoved = () => {} }: ShiftDetailExtendedProps) => {
  const user = useUser();
  const [editForm, setEditForm] = useState(false);
  const [deleteShiftModal, setDeleteShiftModal] = useState(false);

  const queryClient = useQueryClient();
  const { data: shift, isLoading, isFetching } = useQuery(['shiftDetail', id], async () =>
    feathersClient.service('shift').get(id, {
      query: {
        $eager: '[application(withDeleted).[person.personData], manShift.[canceledApplication.[person.personData]], shiftTemplate, perk, documentType, company]',
        $modify: 'withApplicationCounts',
      },
    }));

  const { mutateAsync: removeManShift, isLoading: isRemoving } = useMutation(
    ['remove-man-shift'],
    async (manShiftId: number) =>
      feathersClient.service('man-shift').remove(manShiftId),
    {
      onError: () => {
        ErrorAlert('Nepodařilo se odebrat volné místo ze směny');
      },
      onSuccess: () => {
        SuccessAlert('Volné místo bylo odebráno');
      },
    },
  );

  const { mutateAsync: addOpenManShift, isLoading: isAdding } = useMutation(
    ['create-open-man-shift'],
    async () => feathersClient.service('man-shift').create({ shift_id: shift?.id }),
    {
      onError: () => {
        ErrorAlert('Nepodařilo se přidat místo ke směně');
      },
      onSuccess: () => {
        SuccessAlert('Do objednávky bylo přidáno nové místo');
      },
    },
  );

  const { mutateAsync: patchShift } = useMutation(
    ['patch-shift'],
    async (
      { data, shiftId }: { data: Partial<ShiftData>, shiftId: number },
    ) => feathersClient.service('shift').patch(shiftId, data),
    {
      onError: () => {
        ErrorAlert('Nepodařilo se upravit směnu');
      },
      onSuccess: () => {
        SuccessAlert('Směna byla upravena');
      },
    },
  );

  const { mutateAsync: deleteShift } = useMutation(
    ['delete-shift'],
    async (removeShiftId: number) => feathersClient.service('shift').remove(removeShiftId),
  );

  const { mutateAsync: getInvitation } = useMutation(
    ['get-applications'],
    async (personIds: number[]) => {
      const res = await feathersClient.service('application').find({
        query: {
          $joinRelation: 'shift',
          'application.person_id': { $in: personIds },
          'shift.id': shift?.id,
          'application.invitation': true,
          'application.state': { $ne: ApplicationState.CONFIRMED },
          $limit: -1,
        },
      });
      return getResponseArray(res);
    },
  );

  const menuOptions = [
    {
      text: <Link className="font-normal text-secondary-400" to={`/shift/${id}`}>Upravit</Link>,
      icon: <EditIcon className="text-secondary-400 w-[18px]" />,
    },
    {
      text: 'Smazat směnu',
      icon: <TrashIcon className="text-secondary-400 w-[18px]" />,
      onClick: () => {
        setDeleteShiftModal(true);
      },
    },
    {
      text: <Link className="font-normal text-secondary-300" to={`/company/150/shift-template/${shift?.shift_template_id}`}>Upravit pozici</Link>,
      icon: <EditIcon className="text-secondary-300 w-[18px]" />,
    },
  ];

  const isParticipantChanging = isAdding || isRemoving || isFetching;

  const createArray = useCallback((length: number) => Array.from({ length }, (_, i) => i), []);

  const onPlus = useCallback(async () => {
    await addOpenManShift();
    queryClient.invalidateQueries();
  }, [addOpenManShift, queryClient]);

  const onMinus = useCallback(async (manShifts: ManShiftData[] | undefined) => {
    const manShiftToRemove = manShifts?.find((ms) => ms.application_id === null && ms.deleted_at === null);
    if (!manShiftToRemove) {
      ErrorAlert('Z této objednávky již nelze odebrat místa');
      return;
    }
    await removeManShift(manShiftToRemove.id);
    queryClient.invalidateQueries();
  }, [removeManShift, queryClient]);

  const onSave = useCallback(async ({ modified }: FormState<ShiftDetailFormValues>, shiftData: ShiftData) => {
    const { startTime, endTime, spaces, requirements, emoji } = modified;
    const shiftPatch: Partial<ShiftData> = {
      start_time: startTime?.toISOString(),
      end_time: endTime?.toISOString(),
      contest_end: startTime?.subtract(1, 'hours').toISOString(),
      emoji,
    };

    if (requirements && requirements?.length !== 0) {
      const perks = requirements
        .filter(isPerkData)
        .filter((perk) => !shiftData.perk?.find((shiftPerk) => perk.id === shiftPerk.id));
      const documentTypes = requirements
        .filter(isDocumentTypeData)
        .filter((dt) => !shiftData.documentType?.find((shiftDt) => dt.id === shiftDt.id));

      const perksToPatch = shiftData.perk ? [...shiftData.perk, ...perks] : perks;
      const documentTypesToPatch = shiftData.documentType
        ? [...shiftData.documentType, ...documentTypes]
        : documentTypes;

      shiftPatch.perk = perksToPatch.length === 0 || perks.length === 0 ? undefined : perksToPatch;
      shiftPatch.documentType =
        documentTypesToPatch.length === 0 || documentTypes.length === 0
          ? undefined : documentTypesToPatch;
    }

    if (!shiftData.manShift) {
      ErrorAlert('');
      return;
    }

    if (spaces) {
      const freeSpaceDiff = spaces - Number(shiftData.unfilled_orders_count);
      if (freeSpaceDiff > 0) {
        const manShiftsToAddCount = createArray(freeSpaceDiff);
        await Promise.all(manShiftsToAddCount.map(() => addOpenManShift()));
      } else {
        // TODO: is this even necessary?
        const manShiftsToRemove = shiftData.manShift.filter((ms) =>
          ms.application_id === null && ms.deleted_at === null);
        const manShiftsToRemoveCount = createArray(Math.abs(freeSpaceDiff));
        // eslint-disable-next-line no-restricted-syntax
        for await (const index of manShiftsToRemoveCount) {
          // eslint-disable no-await-in-loop
          await removeManShift(manShiftsToRemove[index].id);
        }
      }
    }
    await patchShift({ shiftId: shiftData.id, data: shiftPatch });
    queryClient.invalidateQueries();
  }, [addOpenManShift, createArray, patchShift, queryClient, removeManShift]);

  const createNewApplication = useCallback(async (
    shiftData: ShiftData,
    tymberId: number,
    manShiftId: number,
  ): Promise<ApplicationData> => {
    const newApplicationData: Partial<ApplicationData> = {
      state: null,
      invitation: true,
      man_shift_id: manShiftId,
      credits: Number(shiftData.invitation_credits),
      payment_base: Number(shiftData.payment_base),
      person_id: tymberId,
      employer_id: shiftData.company_id,
    };
    return feathersClient.service('application').create(newApplicationData);
  }, []);

  const onInvite = useCallback(async (form: FormState<ParticipantsFormValues>, shiftData: ShiftData) => {
    // TODO: error handling
    if (!shiftData.manShift || !form.values.personSelect) return;
    const freeManShifts = shiftData.manShift.filter((ms) => ms.application_id === null && ms.deleted_at === null);
    if (form.values.personSelect.length > freeManShifts.length) {
      const manShiftsToAdd = createArray(form.values.personSelect.length - freeManShifts.length);
      const manshiftPromises = manShiftsToAdd.map(() => addOpenManShift());
      const manShifts = await Promise.all(manshiftPromises);

      freeManShifts.push(...manShifts);
    }

    const existingApplications = await getInvitation(form.values.personSelect.map((tymber) => tymber.id));

    const promises = form.values.personSelect.map(async (tymber, index) => {
      let application = existingApplications.find((app) => app.person_id === tymber.id);
      if (!application) {
        application = await createNewApplication(shiftData, tymber.id, freeManShifts[index].id);
      } else {
        application.state = null;
        application.payment_base = Number(application.payment_base);
        application.credits = Number(application.credits);
        await feathersClient.service('application').patch(application.id, application);
      }
    });
    await Promise.all(promises);

    queryClient.invalidateQueries();
  }, [addOpenManShift, createArray, createNewApplication, getInvitation, queryClient]);

  const onDelete = async (removeShiftId: number) => {
    try {
      const deletedShift = await deleteShift(removeShiftId);
      queryClient.invalidateQueries({ queryKey: ['shifts'] });
      setDeleteShiftModal(false);
      onShiftRemoved(deletedShift);
    } catch (e: unknown) {
      if (e && typeof e === 'object' && Object.hasOwn(e, 'code') && e.code === 403) {
        ErrorAlert('Není možno smazat objednávku, která již proběhla nebo probíhá a je na ní někdo přihlášen.');
      } else {
        ErrorAlert('Objednávku není možné smazat');
      }
      setDeleteShiftModal(false);
    }
  };

  const applications = shift?.application ? [...shift.application] : [];
  shift?.manShift?.forEach((ms) => {
    ms.canceledApplication?.forEach((ca) => {
      applications?.push(ca);
    });
  });
  applications?.sort((prev, next) => {
    const appStateOrder = [
      null, // invitation has no state
      ApplicationState.CONFIRMED,
      ApplicationState.REJECTED,
      ApplicationState.SYSTEM_CANCELED,
      ApplicationState.CANCELED_EARLY,
      ApplicationState.CANCELED_LATE,
    ];

    // order by state first
    if (prev.state !== next.state) {
      return appStateOrder.indexOf(prev.state) - appStateOrder.indexOf(next.state);
    }

    // then by name
    return prev.person?.last_name?.localeCompare(next.person?.last_name ?? '') || 0;
  });

  const excludeParticipants = shift?.application?.map((app) => app.person_id);

  return (
    <div className="p-4">
      <Spinner show={isLoading} className="h-8" />
      {shift
        && (
          <>
            <Form
              onSubmit={
                async (form: FormState<ShiftDetailFormValues>) => {
                  await onSave(form, shift);
                  setEditForm(false);
                }
              }
            >
              <div className="relative mb-4">
                {!editForm
                  ? (
                    <>
                      <ShiftDetail
                        emoji={shift.emoji || shift.shiftTemplate?.emoji}
                        shiftName={shift.name}
                        startTime={shift.start_time}
                        endTime={shift.end_time}
                        spaces={Number(shift.unfilled_orders_count)}
                      />
                      <Options menuOptions={menuOptions} className="absolute top-5 right-5" />
                    </>
                  )
                  : (
                    <ShiftDetailForm
                      className=""
                      shift={shift}
                      userRole={user.role}
                      onCancel={() => setEditForm(false)}
                    />
                  )}
              </div>
              <AvailableSpace
                className="text-secondary-900 mb-4"
                value={(
                  <>
                    <Spinner show={isParticipantChanging} className="size-6 self-center justify-self-center" absolute />
                    {Number(shift.unfilled_orders_count)}
                  </>
                )}
                disabled={isParticipantChanging}
                onPlus={onPlus}
                onMinus={() => onMinus(shift.manShift)}
              />
              <Participants
                current={Number(shift.filled_orders_count) + Number(shift.invitation_orders_count) || 0}
                total={shift.manShift?.length || 0}
                applications={applications}
                exclude={excludeParticipants}
                className="text-secondary-900"
                onInvite={(formState) => onInvite(formState, shift)}
                shift={shift}
              />
            </Form>
            <Modal
              isOpen={deleteShiftModal}
              onRequestClose={() => setDeleteShiftModal(false)}
              contentLabel="Delete shift modal"
              className="bg-bg m-auto rounded-xl px-6 py-4"
              overlayClassName={styles.overlay}
              ariaHideApp={false}
            >
              <div className="flex justify-between">
                <h2 className="text-fg-900 text-[16px] leading-[24px] font-semibold mb-4">
                  Opravdu chcete smazat směnu?
                </h2>
                <button
                  className="p-0"
                  type="button"
                  aria-label="close button"
                  onClick={() => setDeleteShiftModal(false)}
                >
                  <CrossIcon className="w-5 h-5 text-secondary-600" />
                </button>

              </div>
              <div className="text-fg-900 text-sm mb-4">Tato akce je nevratná a směna bude navždy smázána.</div>
              <div className="flex gap-3 justify-end">
                <button
                  type="button"
                  aria-label="close button"
                  className="px-4 py-2 border border-secondary-300 font-semibold text-sm text-secondary-600"
                  onClick={() => setDeleteShiftModal(false)}
                >
                  Zrušit
                </button>
                <button
                  type="button"
                  aria-label="delete button"
                  className="px-4 py-2 border border-[#EF4444] font-semibold text-sm text-bg bg-[#EF4444]"
                  onClick={() => onDelete(shift.id)}
                >
                  Smazat
                </button>
              </div>
            </Modal>
          </>
        )}
    </div>
  );
};

export default ShiftDetailExtended;
