import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';

import feathersClient from '../../../../apiClient';
import { Roles } from '../../../../utils/enums';

export const loadUserDataThunk = createAsyncThunk('user/loadUserData', async (id) => {
  const userData = await feathersClient.service('person-data').get(id);
  return userData;
});

const loadUserDataReducer = {
  [loadUserDataThunk.pending]: (state) => ({
    ...state,
    loading: true,
    error: false,
  }),
  [loadUserDataThunk.rejected]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.message,
    userData: null,
  }),
  [loadUserDataThunk.fulfilled]: (state, action) => ({
    ...state,
    loading: false,
    error: false,
    userData: action.payload.userData,
  }),
};

export const loadUserThunk = createAsyncThunk('user/loadUser', async (id) => {
  const data = await feathersClient.service('login').find({
    id,
    query: { $eager: '[role, person.[company,personData, personDocument]]' },
  });

  loadUserDataThunk(id);
  // const applications = await feathersClient.service('application').get(id);
  const user = {
    first_name: data.data[0].first_name,
    last_name: data.data[0].last_name,
    isVerified: data.data[0].is_verified,
    isActive: data.data[0].deleted_at === null,
    ...data.data[0],
  };
  return { user };
});

const loadUserReducer = {
  [loadUserThunk.pending]: (state) => ({
    ...state,
    loading: true,
    error: false,
  }),
  [loadUserThunk.rejected]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.message,
    user: null,
    userData: null,
  }),
  [loadUserThunk.fulfilled]: (state, action) => ({
    ...state,
    loading: false,
    error: false,
    user: action.payload.user,
    userData: action.payload.userData,
  }),
};

export const updateUserPayment = createAsyncThunk('user/person/personData/patchPersonData', async (body) => {
  const data = await feathersClient.service('person-data').patch(body.id, body.request);
  return data;
});

// TODO create function for updating user
export const updateUserThunk = createAsyncThunk('user/updateUser', async () => {
  // const user = await feathersClient.service('users').patch(_id, updatedData);
  const user = {
    first_name: 'Tester',
    last_name: 'Jensen',
    email: 'tester@jensen.com',
    isVerified: false,
    isActive: true,
    permissions: ['super_admin', 'admin'],
  };

  return { user };
});
const updateUserReducer = {
  [updateUserThunk.pending]: (state) => ({
    ...state,
    loading: true,
    error: false,
  }),
  [updateUserThunk.rejected]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.message,
  }),
  [updateUserThunk.fulfilled]: (state, action) => ({
    ...state,
    loading: false,
    error: false,
    user: action.payload.user,
  }),
};

// TODO create function for sending phone verification
export const sendPhoneVerificationThunk = createAsyncThunk(
  'user/sendPhoneVerification',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async () => {
  },
);

export const createPersonDataThunk = createAsyncThunk('user/createPersonData', async (data) => {
  await feathersClient.service('person-data').create(data.body);
});
const createPersonDataReducer = {
  [createPersonDataThunk.pending]: (state) => ({
    ...state,
    loading: true,
    error: false,
  }),
  [createPersonDataThunk.rejected]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.message,
  }),
  [createPersonDataThunk.fulfilled]: (state, action) => ({
    ...state,
    loading: false,
    error: false,
    userData: action.payload,
  }),
};

export const patchPersonDataThunk = createAsyncThunk('user/patchPersonData', async (data) => {
  await feathersClient.service('person-data').patch(data.id, data.body);
});
const patchPersonDataReducer = {
  [patchPersonDataThunk.pending]: (state) => ({
    ...state,
    loading: true,
    error: false,
  }),
  [patchPersonDataThunk.rejected]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.message,
  }),
  [patchPersonDataThunk.fulfilled]: (state, action) => ({
    ...state,
    loading: false,
    error: false,
    userData: action.payload,
  }),
};

const userSlice = createSlice({
  name: 'user',
  initialState: {
    loading: null,
    error: null,
    user: null,
    userData: null,
    applications: null,
  },
  reducers: {
    removeUser: (state) => ({
      ...state,
      user: null,
      userData: null,
      applications: null,
    }),
    setUser: (state, action) => ({
      ...state,
      user: action.payload,
    }),
  },
  extraReducers: {
    ...loadUserReducer,
    ...loadUserDataReducer,
    ...updateUserReducer,
    ...patchPersonDataReducer,
    ...createPersonDataReducer,
  },
});

export const selectUserError = createSelector(
  (state) => state.user.error,
  (error) => {
    if (error !== null) return error;
    return undefined;
  },
);

export const selectUserHasIdentity = createSelector(
  (state) => state.user.userData,
  (userData) => {
    if (userData === null || userData === undefined) {
      return false;
    } return true;
  },
);

export const selectUser = createSelector(
  (state) => state.user.user,
  (user) => {
    if (user !== null && user !== undefined) return user;
    return undefined;
  },
);

export const selectUserJobHistory = createSelector(selectUser, (user) => {
  if (user !== null && user !== undefined) return user.jobHistory;
  return undefined;
});

export const selectUserRoles = createSelector(
  (state) => state.user.user,
  (user) => user?.role,
);

export const selectIsTymber = createSelector(
  (state) => state.user.user,
  (user) => !!user?.role?.find((x) => x.slug === Roles.TYMBER),
);

export const selectIsAdmin = createSelector(
  (state) => state.user.user,
  (user) => !!user?.role?.find((x) => x.slug === Roles.ADMIN),
);

export const selectIsCompany = createSelector(
  (state) => state.user.user,
  (user) => !!user?.role?.find((x) => x.slug === Roles.COMPANY),
);

export const selectUserPerson = createSelector(
  selectUser,
  (user) => user.person,
);

export const selectUserPersonData = createSelector(
  selectUserPerson,
  (person) => person.personData,
);

export const selectUserPersonDataBankAccount = createSelector(
  selectUserPersonData,
  (personData) => {
    const bankAccount = personData.bank_account.split('/');
    return bankAccount[0];
  },
);

export const selectUserPersonDataBankCode = createSelector(
  selectUserPersonData,
  (personData) => {
    const bankAccount = personData.bank_account.split('/');
    return bankAccount[1];
  },
);

export const { removeUser: removeUserAction, setUser: setUserAction } = userSlice.actions;

export default userSlice.reducer;
