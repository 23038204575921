import { Unavailable, convert } from '@feathersjs/errors';
import { AxiosClient } from '@feathersjs/rest-client';

function toError(error) {
  if (error.code === 'ECONNREFUSED') {
    throw new Unavailable(error.message, _.pick(error, 'address', 'port', 'config'));
  }

  throw convert(error);
}

class CustomAxios extends AxiosClient {
  download(params = {}) {
    return this.request({
      url: this.makeUrl(params.query),
      method: 'GET',
      responseType: 'blob',
      headers: {
        ...params.headers,

      },
    }, { ...params, connection: { ...params.connection, responseType: 'blob' } })
      .catch(toError);
  }
}

export default CustomAxios;
